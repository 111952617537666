import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import './InnerPages.scss';
import React, { Component } from 'react';
import ScriptTag from 'react-script-tag';
import Footer from './Footer.js';

class Merch extends Component {

    handleHomeLink(e) {
        window.location.href = "/"
    }

    componentDidMount() {
        document.body.classList.add('merch')

        window.spread_shop_config = {
            prefix: 'https://sol-comics.myspreadshop.com',
            shopName: 'sol-comics',
            baseId: 'shop',
        };

        window.setTimeout(() => {
            let homelink = document.getElementsByClassName('sprd-header__image')[0]
            homelink.href = "/"
            homelink.onclick = this.handleHomeLink

            document.getElementById("buttonSpreadshirt").remove()
        },1500)
    }

    render() {
        document.body.classList.add('inner');
        
        return (
            <div id="Merch">
                <div id="shop">Store loading...</div>
                
                <ScriptTag type="text/javascript" src="https://sol-comics.myspreadshop.com/js/shopclient.nocache.js" />

                <Footer />

            </div>
        )
    }
}

export default Merch;