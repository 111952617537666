import React from 'react';
import axios from 'axios';
import PaypalSubButtons from './PaypalSubButtons';
import {Button} from 'react-bootstrap';

class JobProgress extends React.Component {
    componentDidMount() {
        axios.post("/.netlify/functions/get_job_progress").then((result) => {
            if(!!result.data && result.data.progress !== undefined) {
                let green_vat = require(`./assets/green_vat_${result.data.progress}.png`)
                document.getElementById('progress-meter').src = green_vat

                let header_text = this.getProgressLine(result.data.progress)
                document.getElementById('progress-text-header').innerHTML = header_text

                let progress_paragraph = this.getProgressParagraph(result.data.progress)

                if(!!progress_paragraph) {
                    document.getElementById('progress-paragraph').innerHTML = "<p>"+progress_paragraph+"</p>"
                }
            } else {
                document.getElementById('job-progress').style.display = "none";
            }
        }).catch((error) => {
            console.log('get_job_progress error: ', error)
            return error
        });
    }

    getProgressLine(progress) {
        let section_index = Math.round((progress / 15) * 6)

        let lines = [
            [
                'This is just the beginning!',
                'Amazing start!',
                'We\'re on our way!',
            ],
            
            [
                'The journey is the best part!',
                'One step at a time!',
                'We\'re getting there!',
            ],
            
            [
                'We can do this!',
                'What an amazing journey!',
                'Reach for the stars!',
            ],

            [
                'We can do this together!',
                'We\'re halfway there!',
                'Keep going!',
            ],
            
            [
                'This is an amazing crew!',
                'We\'re almost there!',
                'Just a little bit further!',
            ],

            ['Wow! We made it!',]
        ]

        let section = lines[section_index]

        let range = section.length - 1

        let line_index = Math.round(Math.random() * range);

        if(line_index < 0) {
            line_index = 0
        }

        let line = section[line_index]

        return line
    }

    getProgressParagraph(progress) {
        let section_index = Math.round((progress / 15) * 6)
        let paragraph

        // if(section_index < 15) {
        //     paragraph = "This project is not yet fully funded. The artist works on it part-time, a little each day, and funds the project himself with income from his current full-time job.  Until this situation changes, it may be a year or two (instead of a month or two) before each episode is released."
        // }

        return paragraph
    }

    render() {
        let content

        if(!!this.props.subscription_status && this.props.subscription_status === 'ACTIVE') {
            content = (
                <>
                    <h3>Thank you for supporting this project!</h3>

                    <p>
                        The thriving narrative of Sliver of Light depends on the foresight and contribution of its crew to continue.  
                        You are the reason this is possible.  
                    </p>
                    <p>                            
                        This dazzling chamber of radioactive breakfast sauce represents the overall project budget, including the creator's pay.  
                        When it's full, we'll be able to devote a full-time schedule to developing this epic story!
                    </p>
                </>
            )
        } else {
            content = (
                <>
                    <h3>Support the Project</h3>
                    
                    <div id="progress-paragraph"></div>

                    <p>
                        The thriving narrative of Sliver of Light depends on the foresight and contribution of its crew to continue.  
                        Consider becoming a Core Crew member for just $5 a month.  
                    </p>

                    <p>                            
                        This dazzling chamber of radioactive breakfast sauce represents the overall project budget, including the creator's pay.  
                        When it's full, we'll be able to devote a full-time schedule to developing this epic story!
                    </p>
                </>
            )
        }

        let sub_buttons

        if(!!this.props.SubscribeButtons && !!this.props.user_id && this.props.subscription_status !== 'ACTIVE') {
            sub_buttons = (
                <div id="paypal_subscription_button_wrapper">
                    <PaypalSubButtons user_id={this.props.user_id} />
                </div>
            )
        } else if(!this.props.user_id) {
            sub_buttons = (
                <div className="buttons">
                    <Button variant="secondary" onClick={()=>{window.netlifyIdentity.open('signup')}}>Sign Up to Subscribe</Button>
                </div>
            )
        }

        return (
            <div id="job-progress-outer">
                <div id="job-progress" className="section-body">
                    <img id="progress-meter" src="" />
                    <div className='progress-text'>
                        <h2 id="progress-text-header"></h2>
                        
                        {content}

                        {sub_buttons}
                    </div>
                </div>
            </div>
        )
    }
}

export default JobProgress;