import Menu from './CQMenu.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import Footer from './Footer.js';
import Breadcrumb from './Breadcrumb.js';
import { usePrismicDocumentByUID, PrismicRichText, PrismicText } from '@prismicio/react'
import { useParams } from "react-router-dom";
import { DiscussionEmbed } from 'disqus-react';
import ImageGallery from 'react-image-gallery';

const TTPOE = ({ match }) => {
    let params = useParams();

    const [article, {state}] = usePrismicDocumentByUID('ttpoe', params.slug)

    var user = window.netlifyIdentity.currentUser();
    var userProfile = localStorage.getItem('user_profile') ? JSON.parse(localStorage.getItem('user_profile')) : {}

    if(!user || !userProfile || userProfile.subscription_status !== 'ACTIVE') {
        window.location.href = '/'
    }

    if(state === 'loaded' && article) {
        document.title = article.data.title[0].text + " - Classified - Sliver of Light"
    }

    return (
        <div className="App inner-page">
            <Menu />

            {state === 'loaded' && article && 
                <Breadcrumb section="To The People of Earth" sectionLink="ttpoe" />
            }

            <div id="Post">
                <div className="section">
                    <div className="section-body">
                        {state === 'loaded' && article && 
                            <div id="post-inner" className={article.data.post_class}>
                                <h2><PrismicText field={article.data.title} /></h2>

                                <div className="section-1">
                                    <PrismicRichText field={article.data.content} />
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {state === 'loaded' && article && 
                    <div className="section">
                        <div className="section-body">
                            <DiscussionEmbed
                                shortname='sliveroflight-1'
                                config={
                                    {
                                        url: "https://sliveroflight.com" + window.location.pathname,
                                        identifier: article.uid,
                                        title: article.data.title[0].text,
                                        language: 'en_US' //e.g. for Traditional Chinese (Taiwan)	
                                    }
                                }
                            />
                        </div>
                    </div>
                }
            </div>

            <Footer />

        </div>
    )
}

export default TTPOE;