import GA from './GA';

function Footer() {
    GA('view');

    return (
        <div id="footer">
            Sliver of Light &amp; SOL Comics Copyright &copy; 2022 Joey livingston.  All rights reserved.
        </div>
    )
}

export default Footer;