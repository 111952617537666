import React, { Component } from 'react';

class Breadcrumb extends Component {

    render() {
        return (
            <div id="Breadcrumb">
                <ul>
                    <li>
                        <a href="/crew">
                            Crew Quarters <span className="arrow">&raquo;</span>
                        </a>
                    </li>
                    <li>
                        <a href={"/crew/" + this.props.sectionLink}>
                            {this.props.section}
                        </a>
                    </li>
                </ul>
            </div>
        )
    }
}

export default Breadcrumb;