import Menu from './CQMenu.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import Footer from './Footer.js';
import { useAllPrismicDocumentsByType, PrismicRichText, PrismicText } from '@prismicio/react'
import React, { useState } from 'react';
import SOLModal from './SOLModal';
import {Button} from 'react-bootstrap';
import getUserProfile from './getUserProfile';
import axios from 'axios';

function Section({title, section}) {
    // Prismic pages, need to list these out in HTML
    const [posts, {state: c_state}] = useAllPrismicDocumentsByType(section)
    const modalClass = 'modal-700px'
    const handleClose = () => setShow(false);
    document.title = title + " - Sliver of Light"

    var user = window.netlifyIdentity.currentUser();
    var userProfile = localStorage.getItem('user_profile') ? JSON.parse(localStorage.getItem('user_profile')) : {}

    if(!user || !userProfile || userProfile.subscription_status !== 'ACTIVE') {
        window.location.href = '/'
    }

    var acceptAgreement = async () => {
        await axios.post("/.netlify/functions/save_user", {
            agreement_date: Date.now(),
            user_id: userProfile.id
        }).then((result) => {
            console.log('agreement saved')
        }).catch((error) => {
            console.log('acceptAgreement error: ', error)
        });

        userProfile = getUserProfile()

        setShow(false)
    }

    var userAgreementModalContent = () => {
        return (
            <div id="user-agreement">
                <h2>User Agreement</h2>

                <p>Welcome to Crew Quarters!  This area is for Core Crew only.  Before you get started, there's a couple of simple rules that need to be understood.</p>

                <ul>
                    <li>The content in this section is classified information.  Downloading or sharing any of the content in this area is strictly prohibited.</li>
                    <li>Your account access is meant for you alone.  Do not share your login information with anyone.</li>
                </ul>

                <p>That's it!  Please acknowledge below that you have read and understand these rules, then make yourself at home.</p>

                <Button onClick={acceptAgreement}>Understood &amp; Agreed.</Button>
            </div>
        )
    }

    const [modalContent, setModalContent] = useState(userAgreementModalContent());

    const [show, setShow] = useState(!userProfile.agreement_date);

    let first_post
    let remaining_posts
    let published_date = ""
    if(!!posts) {
        first_post = posts[0]
        remaining_posts = posts.slice(1)

        published_date = new Date(first_post.data.published_date).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"})
    }

    return (
        <div id="Section" className="App inner-page">
            <Menu />

            <SOLModal show={show} modalContent={modalContent} modalClass={modalClass} disableClose={true} />

            <div className="section">
                <div className="section-body">
                    <div id="categories">
                        {c_state === 'loaded' && posts && 
                            <div id="section_list" className="post_list">
                                <h3 className="header">{title}</h3>
                                <div className="post_list_inner">
                                    <div id="first_post" className="post" key={first_post.id}>
                                        {!!first_post.data.key_image.url && 
                                            <a className="post_image" href={"/crew/"+section+"/"+first_post.uid}>
                                                <img src={first_post.data.key_image.url} alt={first_post.data.key_image.alt} />
                                            </a>
                                        }

                                        <div className="post_content">
                                            {published_date}
                                            
                                            <h3><a href={"/crew/"+section+"/"+first_post.uid}><PrismicText field={first_post.data.title} /></a></h3>

                                            {<PrismicRichText field={first_post.data.preview} />}

                                            <a href={"/crew/"+section+"/"+first_post.uid} className="readmore">Read More</a>
                                        </div>

                                        <div className="bottom"></div>
                                    </div>

                                    <div id="remaining_posts">
                                        {
                                            remaining_posts.map((post,i) => (
                                                <div className={"post post-" + i} key={post.id}>
                                                    {!!post.data.key_image.url && 
                                                        <a href={"/crew/"+section+"/"+post.uid}>
                                                            <img src={post.data.key_image.url} alt={post.data.key_image.alt} />
                                                        </a>
                                                    }
                                                    
                                                    <h3><a href={"/crew/"+section+"/"+post.uid}><PrismicText field={post.data.title} /></a></h3>

                                                    {<PrismicRichText field={post.data.preview} />}

                                                    <a href={"/crew/"+section+"/"+post.uid} className="readmore">Read More</a>

                                                    <div className="bottom"></div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                </div>
            </div>

            <Footer />

        </div>
    )
}

export default Section;