import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import CrewQuarters from './CrewQuarters';
import UserProfile from './UserProfile';
import Classified from './Classified';
import Section from './Section';
import Merch from './Merch';
import TTPOE from './TTPOE';
import Newsletter from './Newsletter';
import reportWebVitals from './reportWebVitals';
import netlifyIdentity from 'netlify-identity-widget';
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { PrismicProvider } from '@prismicio/react'
import { client } from './prismic'
import getUserProfile from './getUserProfile';

window.netlifyIdentity = netlifyIdentity;
// You must run this once before trying to interact with the widget
netlifyIdentity.init();

window.netlifyIdentity.on('login', async() => {  
  let user_profile = await getUserProfile()

  if(!localStorage.getItem('upgrade_promoted')) {
      localStorage.setItem('promote_upgrade','true')
  }

  if(!!user_profile && !!user_profile.subscription_status && user_profile.subscription_status === 'ACTIVE' && !!user_profile.street_address_1) {
      window.location.href = '/crew'
  } else {
      window.location.href = '/user'
  }
});

const root = ReactDOM.createRoot(
  document.getElementById("root")
);

root.render(
  <PrismicProvider client={client}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="crew" element={<CrewQuarters />} />
        <Route path="crew/classified" element={<Section title="Classified" section="classified" />} />
        <Route path="crew/classified/:slug" element={<Classified />} />
        <Route path="crew/ttpoe" element={<Section title="To The People of Earth" section="ttpoe" />} />
        <Route path="crew/ttpoe/:slug" element={<TTPOE />} />
        <Route path="user" element={<UserProfile />} />
        <Route path="merch" element={<Merch />} />
        <Route path="newsletter" element={<Newsletter />} />
      </Routes>
    </BrowserRouter>
  </PrismicProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();