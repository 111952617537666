import React,{Component} from 'react';
import Hamburger from './assets/hamburger.svg';
import Hlogo from './assets/h-logo.svg';

let userProfile = localStorage.getItem('user_profile') ? JSON.parse(localStorage.getItem('user_profile')) : {}

class Menu extends Component {
    render() {
        return (
            <div id="menu-system">
                <div id="menu-inner">
                    <div id="mobile-menu-interface">
                        <img src={Hlogo} alt="Sliver of Light" id="h-logo" onClick={goHome} />
                        <img src={Hamburger} alt="Expand Menu" id="expand-menu" onClick={toggleMenu} />
                    </div>
                    <ul id="menu">
                        {userProfile.subscription_status === 'ACTIVE' &&
                            <li>
                                <a className="menu-link" href="/crew" onClick={toggleMenu}>Crew Quarters</a>
                            </li>
                        }
                        <li>
                            <a className="menu-link" href="/merch" onClick={toggleMenu}>Merch</a>
                        </li>
                        <li>
                            <a className="menu-link" href="/user" onClick={toggleMenu}>User Profile</a>
                        </li>
                        <li>
                            <a className="menu-link" href="#logout" onClick={handleLogout}>Logout</a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

    componentDidMount() {
        window.onscroll = function() {stickyMenu()};
    }
}

var goHome = function() {
    window.location.href = '/'
}

var stickyMenu = function() {
    var menu_system = document.getElementById("menu-system");

    if(window.pageYOffset > 30) {
        menu_system.classList.add("sticky")
    } else {
        menu_system.classList.remove("sticky")
    }
}

var toggleMenu = function(e) {
    var menu_system = document.getElementById("menu-system");

    if(Array.from(menu_system.classList).includes('active')) {
        menu_system.classList.remove("active")
    } else {
        menu_system.classList.add("active")
    }
}

var handleLogout = function(e) {
    e.preventDefault()

    window.netlifyIdentity.logout()
    localStorage.removeItem('user_profile')
    window.setTimeout(() => {
        window.location.href = "/"
    },500)
}

export default Menu;