import React from 'react';
import {Button} from 'react-bootstrap';
import JobProgress from './JobProgress';
import PaypalSubButtons from './PaypalSubButtons';

class MemberStatuses extends React.Component {
    render() {
        return (
            <>
                <JobProgress subscription_status={this.props.userProfile.subscription_status || ''} user_id={this.props.userProfile.id} />
                <div className="membership-statuses">
                    <div className={`member ${!!this.props.userProfile.subscription_status && this.props.userProfile.subscription_status === "ACTIVE" ? "active" : ""}`} id="core-member">                        
                        <div className="header">
                            <h2>Core Crew Member</h2>
                            {(!this.props.userProfile.subscription_status || this.props.userProfile.subscription_status !== 'ACTIVE') ?
                                <span>$5/month</span>
                            :
                                <Button className="current">Your Current Status</Button>
                            }
                        </div>

                        {(!this.props.userProfile.subscription_status || this.props.userProfile.subscription_status !== 'ACTIVE') ?
                            <div id="paypal_subscription_button_wrapper">
                                <PaypalSubButtons user_id={this.props.userProfile.id} />
                            </div>
                        :
                            <p>
                                As a Core Crew Member, you are the force behind everything we do, the power that makes this project possible.
                                Thank you.  The following benefits are the least we can do.
                            </p>
                        }

                        <ul>
                            <li><strong>All Essential Crew benefits</strong><br/>This is just the beginning.</li>
                            <li>
                                <strong>Lifetime Subscription &amp; Early Access</strong><br/>
                                Every issue of Sliver of Light is shipped to you at no additional cost, for the lifetime of your membership.  
                                You'll get your copy before it's even published.<br/>
                                <br/>
                                Issues of SOL will be published on an irregular schedule until this project is fully funded, after which it will be published at least six times a year.
                            </li>
                            <li><strong>Top Secret Content</strong><br/>Get an insider's view of Sliver of Light, including progress videos, character development sheets, insights from the author, and more.</li>
                            <li><strong>Sneak Peeks</strong><br/>Get a glimpse of upcoming episodes and see what's in the works.</li>
                            <li><strong>Elite Store-Wide Discounts</strong><br/>Core Crew Members receive exclusive store-wide discounts on all merch.</li>
                        </ul>

                        {!!this.props.userProfile.subscription_status && this.props.userProfile.subscription_status === 'ACTIVE' && !!this.props.cancelSubscription &&
                            <Button variant="secondary" type="submit" className="cancel" onClick={this.props.cancelSubscription}>Cancel Subscription</Button>
                        }
                    </div>

                    {(!this.props.userProfile.subscription_status || this.props.userProfile.subscription_status !== 'ACTIVE') && 
                        <div id="essential-column">
                            <div className="member" id="essential-member">
                                <div className="header">
                                    <h2>Essential Crew Member</h2>
                                    <span>Free</span>
                                </div>

                                <ul>
                                    <li><strong>Early Access to Upcoming Issues</strong><br/>When the next episode of SOL comes out, you'll be able to get your copy before it's even published.</li>
                                    <li><strong>Exclusive Store Discounts</strong><br/>SOL Crew Members will always get special treatment.</li>
                                </ul>

                                <Button className="current">Your Current Status</Button>
                            </div>
                        </div>
                    }
                </div>
            </>
        );
    }
}

export default MemberStatuses