import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

import Img1 from './assets/preview/SOL_E01_P02.jpg';
import Img2 from './assets/preview/SOL_E01_P03.jpg';
import Img3 from './assets/preview/SOL_E01_P04.jpg';
import Img4 from './assets/preview/SOL_E01_P05.jpg';
import Img5 from './assets/preview/SOL_E01_P06.jpg';
import Img6 from './assets/preview/SOL_E01_P07.jpg';

import Thumb1 from './assets/preview/SOL_E01_P02_thumb.jpg';
import Thumb2 from './assets/preview/SOL_E01_P03_thumb.jpg';
import Thumb3 from './assets/preview/SOL_E01_P04_thumb.jpg';
import Thumb4 from './assets/preview/SOL_E01_P05_thumb.jpg';
import Thumb5 from './assets/preview/SOL_E01_P06_thumb.jpg';
import Thumb6 from './assets/preview/SOL_E01_P07_thumb.jpg';

const images = [
  {
    original: Img1,
    thumbnail: Thumb1,
  },
  {
    original: Img2,
    thumbnail: Thumb2,
  },
  {
    original: Img3,
    thumbnail: Thumb3,
  },
  {
    original: Img4,
    thumbnail: Thumb4,
  },
  {
    original: Img5,
    thumbnail: Thumb5,
  },
  {
    original: Img6,
    thumbnail: Thumb6,
  },
];

class Preview extends React.Component {
  render() {
    return <ImageGallery items={images} />;
  }
}

export default Preview;