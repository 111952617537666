import Menu from './CQMenu.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import Footer from './Footer.js';
import axios from 'axios';

const confirmSubscription = (newsletter_token, mode='default') => {
    axios.post("/.netlify/functions/confirm_newsletter", {
        newsletter_token: newsletter_token,
        mode: mode
    }).then((result) => {
        document.getElementById('newsletter-confirming').classList.add('hide')

        if(mode === 'unsubscribe') {
            document.getElementById('newsletter-unsubscribed').classList.add('active')
        } else {
            document.getElementById('newsletter-confirmed').classList.add('active')
        }
    }).catch((error) => {
        document.getElementById('newsletter-confirming').classList.add('hide')
        document.getElementById('newsletter-problem').classList.add('active')
    });
}

const Newsletter = ({ match }) => {
    const queryParameters = new URLSearchParams(window.location.search)
    const newsletter_token = queryParameters.get("newsletter_token")
    const mode = queryParameters.get("mode")

    confirmSubscription(newsletter_token, mode)

    return (
        <div className="App inner-page">
            <Menu />

            <div className="section">
                <div className="section-body">

                    <div id="newsletter-confirming">
                        <h3>Confirming Newsletter Subscription</h3>

                        <p>
                            One moment...
                        </p>
                    </div>

                    <div id="newsletter-confirmed">
                        <h3>Yay!  You're all set.</h3>

                        <p>
                            Your subscription has been confirmed.
                        </p>
                    </div>

                    <div id="newsletter-unsubscribed">
                        <h3>Sorry to See You Go.</h3>
                        <p>
                            You have been unsubscribed from our newsletter.
                        </p>
                    </div>

                    <div id="newsletter-problem">
                        <h3>There was a problem.</h3>

                        <p>
                            Our apologies, but your newsletter subscription could not be confirmed.
                            Please contact <a href="mailto:support@solcomics.space">support@solcomics.space</a> for assistance.
                        </p>
                    </div>
                        
                </div>
            </div>

            <Footer />

        </div>
    )
}

export default Newsletter;