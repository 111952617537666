import React from 'react';
import { Modal } from 'react-bootstrap';

class SOLModal extends React.Component {
    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose} dialogClassName={this.props.modalClass}>
                <Modal.Header closeButton={!this.props.disableClose}>
                </Modal.Header>
                <Modal.Body>{this.props.modalContent}</Modal.Body>
            </Modal>
        );
    }
}

export default SOLModal