import React from 'react';
import mailbox from './assets/mailbox.png';
import MemberStatuses from './MemberStatuses';

class CrewUpgradeContent extends React.Component {
    render () {
        return (
            <div className="members">
                <img id="epimage" src={mailbox} alt="Upgrade" />

                <p className="centered">We're happy you joined our little crew!<br/>Now consider upgrading your membership to <strong>Core</strong> status.  Everyone should upgrade.  The benefits alone are worth the small monthly fee.  But more importantly, as a Core Crew Member, you'll help to insure that the story continues to be told.</p>
                
                <MemberStatuses userProfile={this.props.userProfile} />
            </div>
        )
    }
}

export default CrewUpgradeContent;