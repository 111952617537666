import Button from 'react-bootstrap/Button';
import React,{Component} from 'react';
import Hamburger from './assets/hamburger.svg';
import Hlogo from './assets/h-logo.svg';
import More from './assets/more.png';

var userProfile = localStorage.getItem('user_profile') ? JSON.parse(localStorage.getItem('user_profile')) : {}

class Menu extends Component {

    render() {
        return (
            <div id="menu-system">
                <div id="mobile-menu-interface">
                    <img src={Hlogo} alt="Sliver of Light" id="h-logo" onClick={goHome} />
                    <img src={Hamburger} alt="Expand Menu" id="expand-menu" onClick={toggleMenu} />
                </div>
                <ul id="menu">
                    <li className="about">
                        <a className="about-link" href="#">About</a>

                        <ul>
                            <li>
                                <a className="menu-link" href="/#the-story" onClick={handleLink}>The Story</a>
                            </li>
                            <li>
                                <a className="menu-link" href="/#the-dream" onClick={handleLink}>The Dream</a>
                            </li>
                            <li>
                                <a className="menu-link" href="/#the-author" onClick={handleLink}>The Author</a>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <a className="menu-link" href="/#preview" onClick={handleLink}>Preview</a>
                    </li>

                    <li>
                        <a className="menu-link" href="/merch" onClick={handleLink}>Merch</a>
                    </li>

                    <li>
                        {!!this.props.isCore &&
                            <>
                                <a className="menu-link" href="/crew">Crew Quarters</a>

                                <ul>
                                    <li>
                                        <a className="menu-link" href="/user">User Profile</a>
                                    </li>
                                </ul>
                            </>
                        }

                        {!this.props.isCore && !!userProfile.id && 
                            <a className="menu-link" href="/user">User Profile</a>
                        }

                        {!this.props.isCore && !userProfile.id && 
                            <a className="menu-link" href="/#join-the-crew" onClick={handleLink}>Join the Crew</a>
                        }
                    </li>

                    <li>
                        <a className="menu-link" href="/#get-in-touch" onClick={handleLink}>Contact</a>
                    </li>

                    <li className="more">
                        {!!userProfile.id ?
                            <a className="menu-link" href="#logout" onClick={handleLogout}>Log Out</a>
                        :
                            <>
                                <a className="menu-link" href="#login" onClick={()=>{window.netlifyIdentity.open('login')}}>Log In</a>
                                <ul>
                                    <li>
                                    <a className="menu-link" href="#login" onClick={()=>{window.netlifyIdentity.open('signup')}}>Sign Up</a>
                                    </li>
                                </ul>
                            </>
                        }
                    </li>

                    {/* <li>
                        <a className="menu-link" href="/#get-in-touch" onClick={handleLink}>Contact</a>
                    </li> */}

                    <li>
                        {!!this.props.orderModal && <Button onClick={this.props.orderModal}>Order</Button>}
                    </li>
                </ul>
            </div>
        );
    }
    
    componentDidMount() {
        window.onscroll = function() {stickyMenu()};

        if(!!window.location.hash) {
            window.setTimeout(()=>{
                scrollToSection(window.location.hash.replace('#',''))
            },500)
        }
    }
}

var goHome = function() {
    window.location.href = '/'
}

var stickyMenu = function() {
    var menu_system = document.getElementById("menu-system");

    var activelinks

    if(window.pageYOffset > 30) {
        menu_system.classList.add("sticky")

        var menulinks = document.querySelectorAll('.menu-link')

        var active_found = false

        menulinks.forEach((link) => {
            var anchor_id = link.attributes.href.nodeValue.replace("/","")
            
            var anchor = document.querySelector(anchor_id)

            if(!anchor) {
                return
            }

            var anchor_top = anchor.getBoundingClientRect()?.top
            var window_height = window.innerHeight;

            link.classList.remove('active')

            if(!active_found && !!anchor_top && anchor_top < window_height) {
                link.classList.add('active')
                if(anchor_top > 0) {
                    active_found = true
                }
            }
        })

        activelinks = document.querySelectorAll('.menu-link.active')
        activelinks.forEach((link, index) => {
            if(index <= activelinks.length - 2) {
                link.classList.remove('active')
            }
        })
    } else {
        menu_system.classList.remove("sticky")

        activelinks = document.querySelectorAll('.menu-link.active')
        activelinks.forEach((link, index) => {
            link.classList.remove('active')
        })
    }
}

var toggleMenu = function(e) {
    var menu_system = document.getElementById("menu-system");

    if(Array.from(menu_system.classList).includes('active')) {
        menu_system.classList.remove("active")
    } else {
        menu_system.classList.add("active")
    }
}

var scrollToSection = function(section_id) {
    let element = document.getElementById(section_id)

    if(!!element) {
        element.scrollIntoView()
    }
}

var handleLink = function(e) {

    let current_path = window.location.pathname

    toggleMenu()

    if(current_path !== '/') {
        return true;
    }
    
    scrollToSection(e.target.hash.replace('#',''))

    // e.preventDefault();
}

var handleLogout = function(e) {
    e.preventDefault()

    window.netlifyIdentity.logout()
    localStorage.removeItem('user_profile')
    window.setTimeout(() => {
        window.location.href = "/"
    },500)
}

export default Menu;