import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import getUserProfile from './getUserProfile';
import axios from 'axios';
import GA from './GA';

class PaypalSubButtons extends React.Component {
    render() {
        return (
            // Live creds
            <PayPalScriptProvider options={{ "client-id": "AaJ_FUTZPD-DPs_S5bruE9vAz-lNnlBOPOgzqKcZs3T-xBdR8AmMEvawkvxL3swC1YoD629xJEIpTCRG", vault: true, intent: "subscription" }}>
            
            {/* Dev creds */}
            {/* <PayPalScriptProvider options={{ "client-id": "AQjNNJjXLwXlGBISFUv5sveTFD62y3EtWTY-Ry-bTU2R9joH5YwxHCjH1jmSQ6Rt7wKOa8C5y1MTFJaa", vault: true, intent: "subscription" }}> */}
                <PayPalButtons
                    createSubscription={(data, actions) => {
                        return actions.subscription
                            .create({
                                // Live sub
                                plan_id: "P-0YB75159EC9823527MKWFQ6Y",

                                // Dev sub
                                // plan_id: "P-2KR58543L9407425NMP73QTY",
                            });
                    }}
                    onApprove={async(data, actions) => {
                        let subscription_id = data.subscriptionID

                        await axios.post("/.netlify/functions/save_user", {
                            subscription_id: subscription_id,
                            user_id: this.props.user_id
                        }).then((result) => {
                            console.log('subscription saved')
                        }).catch((error) => {
                            console.log('save_subscription error: ', error)
                        });

                        await getUserProfile()

                        localStorage.setItem('show_sub_thanks','true')

                        // GA('event', 'Subscription', 'Subscriptions', 5);

                        window.location.href = '/user'
                    }}
                    style={{
                        label: "subscribe",
                    }}
                />
            </PayPalScriptProvider>
        );
    }
}

export default PaypalSubButtons