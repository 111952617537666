import { useLocation } from "react-router-dom"
import ReactGA from "react-ga4";

let delay_view, delay_event

function GA(type='view', action='', action_category='', value=0) {
    const location = useLocation()

    if(type === 'view') {
        if(!!delay_view) {
            clearTimeout(delay_view);
        }

        delay_view = window.setTimeout(()=>{
            ReactGA.initialize( 'G-QL81FKQSC7');

            ReactGA.send({ hitType: "pageview", page: location.pathname });
        },500)
    } else {
        if(!!delay_event) {
            clearTimeout(delay_event);
        }

        delay_event = window.setTimeout(()=>{
            ReactGA.initialize( 'G-QL81FKQSC7');

            ReactGA.event({
                category: action_category,
                action: action,
                value: value, // optional, must be a number
            });
        },500)
    }
}

export default GA;