import logo from './assets/logo-lg.svg';
import logoWhite from './assets/logo-white.svg';
import Conflict from './assets/conflict.png';
import Astronaut from './assets/astronaut.png';
import FirstContact from './assets/first-contact.png';
import Spelunker from './assets/spelunker.png';
import GetInTouch from './assets/get-in-touch.png';
import Book from './assets/book.svg';
import Laser from './assets/laser.png';
import Issue1 from './assets/issue-1.png';
import SOLComicsLogo from './assets/sol-comics.png';
import Menu from './Menu.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import {Form, Button} from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import SOLModal from './SOLModal';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Preview from './Preview.js';
import axios from 'axios';
import Footer from './Footer.js';
import getUserProfile from './getUserProfile';
import CrewUpgradeContent from './CrewUpgradeContent';
import JobProgress from './JobProgress';

const current_year = new Date().getFullYear()

const free_shipping_dates = [
    // Thanksgiving
    {
        free_shipping_start_date: "November 22, " + current_year + " 00:00:00",
        free_shipping_exp_date: "November 27, " + current_year + " 06:00:00",
        free_shipping_text: "Free Shipping on Books Through November 26!",
        free_shipping_bg_color: "#807910",
    },
    // St. Patrick's Day
    {
        free_shipping_start_date: "March 16, " + current_year + " 00:00:00",
        free_shipping_exp_date: "March 23, " + current_year + " 06:00:00",
        free_shipping_text: "Free Shipping on Books Through March 22!",
        free_shipping_bg_color: "#00a843",
    },
    // Labor Day
    {
        free_shipping_start_date: "August 28, " + current_year + " 00:00:00",
        free_shipping_exp_date: "September 2, " + current_year + " 06:00:00",
        free_shipping_text: "Free Shipping on Books Through September 1!",
        free_shipping_bg_color: "#0084f4",
    },
]

const newsletter_signup = true

const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

let loadingProfile = false
let upgradeShown = false
let loading = true
let isSubStatusSet = false

// this can only go from false to true, not the other way around
let isCoreLock = false

// for testing the upgrade modal
// localStorage.setItem('promote_upgrade','true')
// localStorage.removeItem('upgrade_promoted')

function doWeShowUpgrade() {
    if(!!upgradeShown) {
        return false
    }
    
    if(!!localStorage.getItem('promote_upgrade') && !localStorage.getItem('upgrade_promoted')) {
        localStorage.setItem('upgrade_promoted','true')
        localStorage.removeItem('promote_upgrade')
        return true
    }
    
    return false
}

let showUpgrade = doWeShowUpgrade()

function App() {
    const [quantity, setQuantity] = useState(1);
    const [userProfile, setUserProfile] = useState(localStorage.getItem('user_profile') ? JSON.parse(localStorage.getItem('user_profile')) : {});
    const [isCore, setIsCore] = useState(isCoreLock);
    const modalClass = 'modal-700px'
    const handleClose = () => setShow(false);

    const [show, setShow] = useState((!!userProfile.id && userProfile.subscription_status !== 'ACTIVE') ? showUpgrade : false);

    const item_cost = 4.99

    var user = window.netlifyIdentity.currentUser();

    if(!!user && !loadingProfile && !userProfile.id) {
        loadingProfile = true
        setUserProfile(getUserProfile())
    }

    if(!!userProfile.id && !isSubStatusSet && !isCoreLock) {
        if(userProfile.subscription_status === 'ACTIVE') {
            setIsCore(true)
            isCoreLock = true
        }
        isSubStatusSet = true
    }

    const setQuantityHelper = (event) => {
        setQuantity(parseInt(event.target.value))
    }

    const getCrewUpgradeModalContent = () => {
        if(!loading) {
            upgradeShown = true
        }

        return (
            <CrewUpgradeContent userProfile={userProfile} />
        )
    }

    const getFreeShipping = () => {
        const current_timestamp = Date.now()

        for (let i = 0; i < free_shipping_dates.length; i++) {
            const config = free_shipping_dates[i];
            
            const start_timestamp = Date.parse(config.free_shipping_start_date)
            const exp_timestamp = Date.parse(config.free_shipping_exp_date)

            if(current_timestamp >= start_timestamp && current_timestamp < exp_timestamp) {
                // not expired yet
                return config
            }
        }

        return false
    }

    const free_shipping_config = getFreeShipping();

    var subscriptionButtonLoadAttempts = 0

    const crewUpgrade = () => {
        if(!show) {
            setModalContent(getCrewUpgradeModalContent())
            setShow(true)

            window.setTimeout(()=>{
                // #paypal_subscription_button_wrapper
                let buttons = document.getElementById('paypal_subscription_button_wrapper').getElementsByClassName('paypal-buttons-label-subscribe')
                if((!buttons || buttons.length === 0) && subscriptionButtonLoadAttempts < 5) {
                    setShow(false)
                    subscriptionButtonLoadAttempts++
                    window.setTimeout(()=>{
                        crewUpgrade()
                    },500)
                }
            },500)
        }
    }

    const getPreview = () => {
        return (
            <Preview />
        )
    }

    const setPreviewModalContent = () => {
        if(!show) {
            setModalContent(getPreview)
            setShow(true)
        }
    }

    const CalculateShipping = () => {
        if(!!free_shipping_config) {
            return 0;
        } else {
            const max_shipping_cost = 5;

            const shipping_cost_matrix = {
                1: 1,
                2: 2,
                3: 2.5,
                4: 3,
                5: 3.5,
                6: 4,
                7: 4.5
            }

            return !!shipping_cost_matrix[quantity] ? shipping_cost_matrix[quantity] : max_shipping_cost;
        }
    }

    const GetPaypal = (params) => {

        let total_shipping = CalculateShipping();
        let total_cost = Math.round((item_cost * quantity) * 100) / 100
        let grand_total = Math.round((total_cost + total_shipping) * 100) / 100

        return (
            <>
                {/* Live Creds */}
                <PayPalScriptProvider options={{ "client-id": "AaJ_FUTZPD-DPs_S5bruE9vAz-lNnlBOPOgzqKcZs3T-xBdR8AmMEvawkvxL3swC1YoD629xJEIpTCRG" }}> 

                {/* Dev creds */}
                {/* <PayPalScriptProvider options={{ "client-id": "AQjNNJjXLwXlGBISFUv5sveTFD62y3EtWTY-Ry-bTU2R9joH5YwxHCjH1jmSQ6Rt7wKOa8C5y1MTFJaa" }}> */}
                    <PayPalButtons 
                        createOrder={(data, actions) => {
                            return actions.order.create({
                                purchase_units: [
                                    {
                                        "description":"Sliver of Light, Issue 1, Qty " + quantity,
                                        "amount":{
                                            "currency_code":"USD",
                                            "value":grand_total,
                                            "breakdown":{
                                                "item_total":{
                                                    "currency_code":"USD",
                                                    "value":total_cost
                                                },
                                                "shipping":{
                                                    "currency_code":"USD",
                                                    "value":total_shipping
                                                },
                                                "tax_total":{
                                                    "currency_code":"USD",
                                                    "value":0
                                                }
                                            }
                                        }
                                    }
                                ]
                            });
                        }}
                        onApprove={(data, actions) => {
                            return actions.order.capture().then((details) => {
                                let od = details.purchase_units[0]

                                let shipping_cost = 0

                                if(!!od.amount.breakdown && !!od.amount.breakdown.shipping.value) {
                                    shipping_cost = od.amount.breakdown.shipping.value
                                } else if(!!od.amount.shipping && !!od.amount.shipping.value) {
                                    shipping_cost = od.amount.shipping.value
                                }

                                let order = {
                                    description: od.description,
                                    total_cost: od.amount.breakdown ? od.amount.breakdown.item_total.value : od.amount.value,
                                    shipping_cost: shipping_cost,
                                    full_name: od.shipping.name.full_name,
                                    email: details.payer.email_address,
                                    shipping_street: od.shipping.address.address_line_1,
                                    shipping_street_2: od.shipping.address.address_line_2,
                                    shipping_city: od.shipping.address.admin_area_2,
                                    shipping_region: od.shipping.address.admin_area_1,
                                    shipping_country: od.shipping.address.country_code,
                                    shipping_postcode: od.shipping.address.postal_code,
                                }

                                if(!!userProfile) {
                                    order.user_id = userProfile.id
                                }
                                
                                setModalContent(ThankYouOrder())
                                
                                // This is causing major problems, need troubleshooting
                                // before re-enabling
                                // GA('event', 'Book Order', 'Book Store', order.total_cost);

                                axios.post("/.netlify/functions/save_order", {
                                    order: order
                                }).then((result) => {
                                    console.log('order saved')
                                }).catch((error) => {
                                    console.log('save_order error: ', error)
                                });
                            });
                        }}
                        style={
                            { 
                                layout: "vertical"
                            }
                        } 
                    />
                </PayPalScriptProvider>
            </>
        )
    };

    const Cart = (params) => {
        let editable = !!params.editable

        let total_shipping = CalculateShipping();
        let total_cost = Math.round((item_cost * quantity) * 100) / 100
        let grand_total = Math.round((total_cost + total_shipping) * 100) / 100

        let shipping_usd = formatter.format(total_shipping)
        let total_usd = formatter.format(grand_total)

        return (
            <>
                <div className="cart">
                    <div className="cart-item">
                        <div className="item-img">
                            <img src={Issue1} alt="SOL Issue 1" />
                        </div>
                        <div className="item-desc">
                            Sliver of Light<br/>
                            Issue 1<br/>
                            28 Pages + Cover
                        </div>
                        <div className="price">
                            {item_cost}
                        </div>
                        <div className="qty">
                            {!!editable ?
                            <select name="quantity" className="form-select" onChange={setQuantityHelper} defaultValue={quantity}>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                            </select>
                            : quantity}
                        </div>
                        <div className="total">
                            {total_cost}
                        </div>
                    </div>
                </div>
                <div className="final_details">
                    <div className="shipping detail">
                        {!!free_shipping_config ?
                            <>Shipping: <span className="cost"><span className="free-shipping-cart">FREE</span></span></>
                        :
                            <>Shipping: <span className="cost">${shipping_usd}</span></>
                        }
                    </div>
                    <div className="total detail">
                        Total: <span className="cost">${total_usd}</span>
                    </div>
                </div>
            </>
        )
    }

    const getIssue1OrderModalContent = () => {
        return (
            <div id="order-issue-1" className="order">
                <h1>Complete Your Order</h1>

                <p className="top">
                    Sliver of Light is printed in batch, and shipped once per month.  
                    Be sure to get your order in now, and please be patient, because another batch will be shipping soon!
                </p>

                <Cart editable={true}/>

                <div id="paypal_order_buttons_wrapper">
                    <GetPaypal />
                </div>
            </div>
        )
    }

    var orderButtonLoadAttempts = 0

    const buyIssue1 = () => {
        if(!show && !loading) {
            setQuantity(1)
            setModalContent(getIssue1OrderModalContent(quantity))
            setShow(true)

            window.setTimeout(()=>{
                // #paypal_order_buttons_wrapper
                let buttons = document.getElementById('paypal_order_buttons_wrapper').getElementsByClassName('paypal-buttons')
                if((!buttons || buttons.length === 0) && orderButtonLoadAttempts < 5) {
                    setShow(false)
                    orderButtonLoadAttempts++
                    window.setTimeout(()=>{
                        buyIssue1()
                    },500)
                }
            },500)
        }
    }

    const ThankYouOrder = () => {
        return (
            <div id="thank-you" className="order">
                <h1>Thank You!</h1>

                <div className="top">
                    <p>Your order was completed successfully, and will be shipped to you in our next batch.</p>
                </div>

                <Cart editable={false} />
            </div>
        )
    }

    const handleLogout = () => {
        window.netlifyIdentity.logout()
        localStorage.removeItem('user_profile')
        window.location.reload()
    }

    const JoinTheCrewCard = () => {
        if(!user) {
            return (
                <div className="body-content">
                    <h2>Join the Crew</h2>

                    <p>
                        Becoming an Essential SOL Crew Member is free.  All you need to do is register and sign in. 
                        This will give you early access to upcoming issues, store discounts, and more.
                    </p>

                    <p>
                        Upgrade to Core Crew status, and you will receive unlimited access to top secret content, sneak peaks,
                        store discounts, and every issue of Sliver of Light is shipped to you at no additional charge.
                    </p>

                    <div className="buttons">
                        <Button variant="secondary" onClick={()=>{window.netlifyIdentity.open('signup')}}>Sign Up</Button>
                        <Button variant="secondary" onClick={()=>{window.netlifyIdentity.open('login')}}>Log In</Button>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="body-content">
                    <h2>Welcome to the Crew!</h2>

                    {!!userProfile.subscription_status && userProfile.subscription_status ==='ACTIVE' ?
                        <>
                            <p>
                                Thank you for being a <strong>Core Crew Member</strong>.  You are the backbone of this project.  Your contribution will help this story to be told, and this project to be published on a more frequent schedule.
                            </p>

                            <Button variant="secondary" onClick={()=>{handleLogout()}}>Log Out</Button>
                        </>
                    :
                        <>
                            <p>
                                You are an Essential SOL Crew Member.  Thank you!  Your membership is free, and gives you early access to upcoming issues, store discounts, and more.
                            </p>

                            <p>
                                Upgrade to Core Crew status now, and you will receive unlimited access to top secret content, sneak peaks, store discounts, 
                                and every issue of Sliver of Light is shipped to you at no additional cost.
                            </p>

                            <div className="buttons">
                                <Button variant="secondary" onClick={crewUpgrade}>Upgrade</Button>
                                <Button variant="secondary" onClick={()=>{handleLogout()}}>Log Out</Button>
                            </div>
                        </>
                    }
                    
                </div>
            )
        }
    }

    const NewsLetterCard = () => {
        if(!!newsletter_signup) {
            return (
                <div className="newsletter-section section">
                    <div className="newsletter-strip">
                        <div className="section-body">
                            <span id="the-newsletter" className="anchor"></span>

                            <img src={Astronaut} id="astronaut-2" alt="Astronaut" />

                            <Form id="newsletter-signup">
                                <h2>Do you like...free comics?</h2>

                                <p>
                                    Signup for our periodic newsletter, and receive free panels from the comic, 
                                    in order!  Read the comic for free, and be notified when we are offering 
                                    free shipping or discounts on books and merch.  We'll also hold several raffles 
                                    for free comics throughout the year, exclusively for newsletter subscribers!
                                </p>
                                <Form.Group className="formRow" id="newsletter-signup-form">
                                    <Form.Control type="text" placeholder="Enter your email address" name="newsletter-email" id="newsletter-email" />
                                    <Button variant="primary" type="submit" onClick={subscribeToNewsletter}>
                                        Sign&nbsp;Up
                                    </Button>
                                </Form.Group>
                                <div id="newsletter-warning">You must provide a valid email address.</div>
                                <div id="newsletter-success">Almost there!  A confirmation email has been sent to your inbox.  Please follow the instructions inside.</div>
                            </Form>
                        </div>
                    </div>
                </div>
            )   
        }
    }

    const subscribeToNewsletter = (e) => {
        e.preventDefault()

        document.getElementById('newsletter-warning').classList.remove('active')
        
        let email = document.getElementById('newsletter-email').value

        if(!email.trim() || !email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            document.getElementById('newsletter-warning').classList.add('active')
            return
        }

        axios.post("/.netlify/functions/send_newsletter_token", {
            email: email
        }).then((result) => {
            document.getElementById('newsletter-email').value = ""
            document.getElementById('newsletter-signup-form').classList.add('hide')
            document.getElementById('newsletter-success').classList.add('active')
        }).catch((error) => {
            console.log('newsletter_subscription error: ', error)
        });
    }


    const [modalContent, setModalContent] = useState(getCrewUpgradeModalContent());

    useEffect(() => {
        if(loading || !upgradeShown) {
            loading = false
            return
        }
        setModalContent(getIssue1OrderModalContent())
    }, [quantity]);

    const sendContactForm = (e) => {
        e.preventDefault()

        document.getElementById('contact-warning').classList.remove('active')
        document.getElementById('contact-success').classList.remove('active')
        
        let name = document.getElementById('contact-name').value
        let email = document.getElementById('contact-email').value
        let message = document.getElementById('contact-message').value

        if(!name.trim() || !email.trim() || !message.trim()) {
            document.getElementById('contact-warning').classList.add('active')
            return
        } else {
            document.getElementById('contact-warning').classList.remove('active')
        }

        let body = "Name: " + name + "<br>"
        body += "Email: " + email + "<br>"
        body += "Message: " + message

        axios.post("/.netlify/functions/send_email", {
            to: "support@solcomics.space",
            from: "support@solcomics.space",
            subject: "Contact Form, Sliver of Light",
            body: body,
        }).then((result) => {
            document.getElementById('contact-name').value = ""
            document.getElementById('contact-email').value = ""
            document.getElementById('contact-message').value = ""

            document.getElementById('contact-success').classList.add('active')
        }).catch((error) => {
            console.log('send_email error: ', error)
        });
    }

    return (
        <div id="App" className="App">
            <Menu orderModal={buyIssue1} isCore={isCore} />

            <SOLModal show={show} handleClose={handleClose} modalContent={modalContent} modalClass={modalClass} />
            
            <header className="App-header section" id="the-story">
                
                {!!free_shipping_config &&
                    <div onClick={buyIssue1} style={{backgroundColor:free_shipping_config.free_shipping_bg_color}} className="free-shipping">{free_shipping_config.free_shipping_text}</div>
                }
                
                <img src={logo} className="App-logo" id="top-logo" alt="logo" />
                
                <span id="the-story" className="anchor"></span>

                <Button onClick={buyIssue1}>Order Episode 1 Now</Button>

                <img src={Conflict} id="conflict" alt="Conflict" />

                <p>
                    Something strange is brewing in this little neighborhood.  Are they rocket scientists working on a bare-bones budget?  
                    Or are these kids about to blow up their garage?  When newly formed particles of cosmic litter 
                    start dreaming about life beyond the exosphere, not even the mailman is safe.
                </p>

                <img src={Astronaut} id="astronaut" alt="Astronaut" />

                <div className="bottom-curtain"></div>
            </header>

            <div id="the-dream-wrap" className="product-display section">
                <span id="the-dream" className="anchor"></span>
                <div className="section-body">
                    <img src={Issue1} className="issue-1" alt="Issue 1" />

                    <h2>The Dream</h2>

                    <p>
                        The idea for Sliver of Light was born from a single illustration by Joey Livingston, 
                        of kids building something together in their garage.  It was an expression of hope 
                        that this spirit of curiosity and invention would continue to be passed down to our future generations.
                    </p>

                    <p>
                        Since then, like those kids in that first simple sketch, 
                        it's taken many bumps, bruises, cataclysmic crashes, tears of rage and relief, 
                        to finally arrive at something truly beautiful and original.  
                        Now, after multiple rewrites, revisions, and complete page redraws, 
                        we find ourselves with a perfect seed for 
                        epic storytelling in the uncountable episodes to follow.
                    </p>

                    <Button onClick={buyIssue1}>Order Episode 1 Now</Button>
                </div>
                <div className="curtain"></div>
            </div>

            <div id="the-author-wrap" className="section">
                <span id="the-author" className="anchor"></span>
                <img src={FirstContact} id="first-contact" alt="First Contact" />

                <div className="section-body">
                    <h2>The Author</h2>

                    <p>
                        Joey Livingston is a professional illustrator and software engineer residing in Florida.  
                        He currently serves as art director for a bi-weekly comic strip, as well as lead engineer 
                        for an international product development agency.  In his youth, he fell in love with 
                        the work of Bill Watterson, Marc Silvestri, and other legendary artists.  
                        He dreamt of one day telling a story of his own through this magical medium.  
                        As an adult, he realized that he would never achieve this dream without
                        a single inspiring idea from which to begin.  
                        So after many sketches and writing attempts, Sliver of Light was born.  
                        He has worked in his spare time since then to bring this project into a fully developed state. 
                    </p>
                </div>
            </div>

            <div id="credits" className="section">
                <img src={logoWhite} id="cavern-logo" alt="logo" />
                <img src={Spelunker} id="spelunker" alt="Spelunker" />

                <div className="section-body">
                    <h2>Episode 1</h2>
                    <h3>The Freshman</h3>

                    <h2>Written &amp; Illustrated By</h2>
                    <h3>Joey Livingston</h3>

                    <h2>Presented By</h2>
                    <h3>SOL Comics</h3>

                    <h2>Special Thanks To</h2>
                    <h4>NCG Studios</h4>
                    <h4>The Graphic Concepts Society</h4>

                    <div id="gcs">
                        <h5>John Dabney</h5>
                        <h5>Tommy Austin</h5>
                        <h5>Nick Geist</h5>
                    </div>

                    <p className="copyright">
                        Sliver of Light &amp; SOL Comics<br/>
                        Copyright &copy; 2022 Joey Livingston.  All rights reserved.
                    </p>

                    <span id="preview" className="anchor"></span>
                    <div id="preview-link">
                        <img src={Book} id="book" alt="Preview" />
                        <Button variant="secondary" onClick={setPreviewModalContent}>Open Preview</Button>
                    </div>
                </div>
            </div>

            <div id="available-now" className="product-display section">
                <div className="section-body">
                    <img src={Issue1} className="issue-1" alt="Issue 1" />

                    <div className="section-text">
                        <h2>Issue One Available Now</h2>

                        <p>
                            <strong>Sliver of Light</strong>, Issue 1, <strong>“The Freshman”</strong>, is 28 pages of manic adventure across beautiful and impossible landscapes.  
                            The story and dialogue is full of hilarity and heart, friction and foreshadowing, and will leave you wanting more.
                        </p>

                        <Button onClick={buyIssue1}>Order Episode 1 Now</Button>
                    </div>
                </div>
                <div className="curtain"></div>
            </div>

            <div id="join-the-crew-wrap" className="section">
                <span id="join-the-crew" className="anchor"></span>
                <img src={Laser} id="laser" alt="Laser" />
                <div className="section-body">
                    <JoinTheCrewCard />
                </div>
            </div>

            <div id="job-progress-wrap" className="section">
                <JobProgress SubscribeButtons={true} user_id={userProfile.id || false} subscription_status={userProfile.subscription_status || ''} />
            </div>

            <NewsLetterCard />

            <div id="get-in-touch-wrap" className="section">
                <span id="get-in-touch" className="anchor"></span>
                <div className="section-body">
                    <h2>Get in Touch</h2>
                    
                    <img src={GetInTouch} id="get-in-touch" alt="Get in Touch" />

                    <p>
                        Problems?  Requests?  Poetic declarations of love and adulation?  We want to hear from you!  
                        Please keep in mind, though, that this is currently a small team.  We try to respond within 24 hours, 
                        but depending on the current volume of feedback, it could take longer.
                    </p>

                    <Form id="contact">
                        <Form.Group className="formRow">
                            <Form.Control type="text" placeholder="name *" name="name" id="contact-name" />
                            <Form.Control type="email" placeholder="email *" name="email" id="contact-email" />
                        </Form.Group>

                        <Form.Group className="formRow">
                            <Form.Control as="textarea" rows={5} name="message" id="contact-message" placeholder="message and additional information *" />
                        </Form.Group>

                        <Form.Group className="formRow submitRow">
                            <div id="contact-warning" className="form-warning form-response">All fields are required.</div>
                            <div id="contact-success" className="form-success form-response">Thank you!  Your message was sent.  Someone will be in contact with you ASAP.  We have a small team, so thanks for your patience.</div>
                            <Button variant="secondary" type="submit" onClick={sendContactForm}>
                                Send Message
                            </Button>
                        </Form.Group>
                    </Form>
                </div>

                <img src={SOLComicsLogo} id="sol-comics-logo" alt="SOL Comics" />
                
            </div>

            <Footer />

        </div>
    );
}

export default App;