import axios from 'axios';

var getUserProfile = async () => {
    var user = window.netlifyIdentity.currentUser();

    if(!user) return false;

    return await axios.post("/.netlify/functions/get_user", {
        email: user.email
    }).then((result) => {
        localStorage.setItem('user_profile', JSON.stringify(result.data))
        return result.data
    }).catch((error) => {
        console.log('get_user error: ', error)
    });
}

export default getUserProfile