import Menu from './CQMenu.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import './InnerPages.scss';
import React, { useState } from 'react';
import Footer from './Footer.js';
import {Form, Button} from 'react-bootstrap';
import MemberStatuses from './MemberStatuses.js';
import axios from 'axios';
import getUserProfile from './getUserProfile';
import SOLModal from './SOLModal';

var userProfile = localStorage.getItem('user_profile') ? JSON.parse(localStorage.getItem('user_profile')) : {}

let loadingProfile = false

var SaveUser = (e) => {
    e.preventDefault();

    let reqs = Array.from(document.getElementsByClassName('required'))

    let validated = true

    reqs.forEach((req) => {
        if(req.value.trim() === '') {
            req.classList.add('problem')
            validated = false
        } else {
            req.classList.remove('problem')
        }
    })

    if(!validated) {
        document.getElementById('profile-warning').classList.add('active')
        return
    } else {
        document.getElementById('profile-warning').classList.remove('active')
    }

    console.log('SaveUser 1')
    let params = {
        user_id: userProfile.id,
        subscribe_to_newsletter: document.getElementById('subscribe_to_newsletter').checked,
        first_name: document.getElementById('first_name').value,
        last_name: document.getElementById('last_name').value,
        street_address_1: document.getElementById('street_address_1').value,
        street_address_2: document.getElementById('street_address_2').value,
        city: document.getElementById('city').value,
        state: document.getElementById('state').value,
        postcode: document.getElementById('postcode').value,
        country: 'US'
    }
    console.log('SaveUser 2')

    axios.post("/.netlify/functions/save_user", params).then((result) => {
        document.getElementById('profile-success').classList.add('active')

        userProfile = getUserProfile()

        window.setTimeout(() => {
            document.getElementById('profile-success').classList.remove('active')
        },10000)
        console.log('profile saved')
    }).catch((error) => {
        console.log('save_user error: ', error)
    });
}

var updateNewsletterPref = async() => {
    console.log('updateNewsletterPref 1')
    let params = {
        user_id: userProfile.id,
        subscribe_to_newsletter: document.getElementById('subscribe_to_newsletter').checked
    }
    console.log('updateNewsletterPref 2')

    console.log('params: ', params)

    await axios.post("/.netlify/functions/save_user", params).then(async (result) => {
        userProfile = await getUserProfile()
        console.log('userProfile: ', userProfile)

        console.log('newsletter preference saved')
    }).catch((error) => {
        console.log('save_order error: ', error)
    });
}

var modalContent = ""

const SubThanksModalContent = (
<div id="sub-thanks">
    <h1>You're All Set.</h1>
    <p>
        You are now officially a <strong>Core Crew Member</strong>.  Thank you!  You are the force behind everything we do, the power that makes this project possible.
    </p>
</div>
)

// localStorage.setItem('show_sub_thanks','true')

function UserProfile() {
    const [showModal, setShowModal] = useState(false);
    const [userProfile, setUserProfile] = useState(localStorage.getItem('user_profile') ? JSON.parse(localStorage.getItem('user_profile')) : {});

    var user = window.netlifyIdentity.currentUser();

    if(!!user && !loadingProfile && !userProfile.id) {
        loadingProfile = true
        setUserProfile(getUserProfile())
    }

    document.title = "User Profile - Sliver of Light"

    if(!!localStorage.getItem('show_sub_thanks')) {
        localStorage.removeItem('show_sub_thanks')
        modalContent = SubThanksModalContent
        setShowModal(true)
    }

    const handleClose = () => {
        setShowModal(false)
    }

    const CancelSubscriptionContent = (
    <div id="sub-thanks">
        <h1>Sorry to See You Go.</h1>
        <p>
            We hate to lose any crew member, but if you must go, we understand and wish you well.
        </p>
        <p>
            In order to cancel your subscription, Paypal requires that you log into your Paypal account, navigate to the recurring payments portion of your profile, and cancel the billing agreement from there.
        </p>
        <a href="https://www.paypal.com/us/smarthelp/article/How-do-I-cancel-a-Billing-Agreement-FAQ2254" target="_blank" className="more-cancel">Go to Paypal</a>
    </div>
    )

    const cancelSubscription = (e) => {
        modalContent = CancelSubscriptionContent
        setShowModal(true)
        e.preventDefault()
    }

    document.body.classList.add('inner');

    return (
        <div id="UserProfile" className="App inner-page">
            <Menu />

            <SOLModal show={showModal} handleClose={handleClose} modalContent={modalContent} modalClass='modal-700px' />

            <div className="section">
                <div className="section-body">
                    <h2>User Profile</h2>

                    <Form id="profile">

                        <div className="formSection">
                            <Form.Group className="formRow" id="subscribe-to-newsletter-row">
                                <div className="label">
                                    <h3>Subscribe to Newsletter</h3>

                                    <p>
                                        This newsletter is for crew members only.  It includes early notifications of upcoming episodes, 
                                        exclusive store discounts, and more.
                                    </p>
                                </div>
                                
                                <Form.Check type="switch" onChange={updateNewsletterPref} name="subscribe_to_newsletter" id="subscribe_to_newsletter" defaultChecked={userProfile.subscribe_to_newsletter}/>
                            </Form.Group>
                        </div>

                        {!!userProfile.subscription_status && userProfile.subscription_status === 'ACTIVE' && 
                            <div className="formSection">
                                <h3>Shipping Address</h3>

                                <p>
                                    As a Core Crew Member, you are entitled to a free copy of every episode.  Enter your shipping address below, 
                                    and we will send each episode to you as soon as it's ready.
                                </p>

                                <Form.Group className="formRow">
                                    <Form.Control type="text" placeholder="First Name" name="first_name" id="first_name" defaultValue={userProfile.first_name} />
                                    <Form.Control type="text" placeholder="Last Name" name="last_name" id="last_name" defaultValue={userProfile.last_name} />
                                </Form.Group>

                                <Form.Group className="formRow">
                                    <Form.Control type="text" placeholder="Street Address *" name="street_address_1" id="street_address_1" className="required" defaultValue={userProfile.street_address_1} />
                                </Form.Group>

                                <Form.Group className="formRow">
                                    <Form.Control type="text" placeholder="Street Address 2" name="street_address_2" id="street_address_2" defaultValue={userProfile.street_address_2} />
                                </Form.Group>

                                <Form.Group className="formRow">
                                    <Form.Control type="text" placeholder="City *" name="city" id="city" className="required" defaultValue={userProfile.city} />
                                    
                                    <Form.Select name="state" id="state" className="required" defaultValue={userProfile.state}>
                                        <option value="">State *</option>
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="DC">District of Columbia</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">West Virginia</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>
                                    </Form.Select>
                                    
                                    <Form.Control type="text" placeholder="Zip Code *" name="postcode" id="postcode" className="required" defaultValue={userProfile.postcode} />
                                </Form.Group>

                                <Form.Group className="formRow submitRow">
                                    <div id="profile-warning" className="form-warning form-response">You must fill in all required fields.</div>
                                    <div id="profile-success" className="form-success form-response">Your profile has been saved.</div>
                                    <Button variant="secondary" type="submit" className="save" onClick={SaveUser}>Save</Button>
                                </Form.Group>
                            </div>    
                        }                            

                        <div className="formSection">
                            <MemberStatuses userProfile={userProfile} cancelSubscription={cancelSubscription} />
                        </div>
                    </Form>
                </div>
            </div>

            <Footer />

        </div>
    )
}

export default UserProfile;